<script>
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				detail: {},
			}
		},
		computed: {
			mySlug() {
				return this.$route.params.slug
			}
		},
		async mounted() {
			this.$set(this.$root, 'page', this)
			this.getProduct()
		},
		methods: {
			getProduct() {
				this.loadingOverlay = true
				Gen.apirest("/product-detail/" + this.mySlug, {}, (err, resp) => {
					this.loadingOverlay = false
					if (err) console.log(err)
					if (resp.code == 404) return this.$router.push({
						name: '404'
					})
					this.detail = resp.data
					setTimeout(() => {
						global.SEMICOLON.initialize.lightbox();
					}, 300)
				})
			},
			clickShop(v) {
				Gen.apirest("/add-count-shop", v, (err)=>{
	                this.loadingOverlay = false
	            }, "POST")
			}
		},
		watch: {
			$route() {
				this.getProduct()
			}
		}
	};
</script>
<template>
	<section id="content">
		<div class="content-wrap">
			<section class="section nobg nomargin">
				<div class="container">
					<div class="prod-detail">
						<div class="row">
							<div class="col-md-4" data-aos="fade-up">
								<div class="pd-img">
									<div class="pd-img-bg"
										:style="'background-image:url('+assets('fo_images','product-bg.png')+')'">
									</div>
									<img :src="uploader(detail.mp_image)" :alt="detail.mp_title"
										:title="detail.mp_title">
								</div>
								<div class="text-center">
									<a v-if="detail.mp_nutrition_fact" href="javascript:;" data-toggle="modal"
										data-target="#nutriFact"
										class="box-btn mb-1 mr-1">{{web.btn_nutrition_facts}}</a>
									<router-link v-if="detail.isRecipe=='Y'"
										:to="{name:'Resep',query:{product:detail.mp_slug}}" class="box-btn mb-2">
										{{web.btn_kreasi_resep}}</router-link>
									<a v-if="detail.mp_link_shop" @click="clickShop(detail)" :href="detail.mp_link_shop"
										target="_blank" class="primary-btn ml-1">{{web.btn_buy_now}}</a>
								</div>
							</div>
							<div class="col-md-8">
								<div class="card mt-3 mt-md-0">
									<div class="card-body">
										<div class="pd-knowledge">
											<div class="heading-block" data-aos="fade-up" id="descHead"
												data-aos-delay="200">
												<h1 class="nott ls0" v-html="detail.mp_title"></h1>
											</div>
											<div class="pk-desc" data-aos="fade-up" data-aos-anchor="#descHead"
												data-aos-delay="400" v-html="detail.mp_desc"></div>
										</div>
										<div class="variants"
											v-if="detail.variant.length&&detail.variant[0].mp_sub_variant[0].field_value">
											<h4>Variants</h4>
											<div class="var-val" v-for="(v,k) in detail.variant" :key="k">
												<template v-if="detail.variant">
													<strong>{{ v.variant }}</strong>
													<a  v-for="(v2,k2) in v.mp_sub_variant"
														:key="k2">{{ v2.field_value }}</a>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="section bg-white nomargin">
				<div class="container">
					<div class="heading-block center">
						<h2>{{web.lbl_produk_lainnya}}</h2>
					</div>
					<div v-if="detail.other_product.length<1">
						<h3 class="text-center">{{web.no_produk_terkait}}</h3>
					</div>
					<div v-else class="margin-n72">
						<div class="row">
							<div v-for="(v2,k2) in detail.other_product" :key="k2" class="col-md-3 col-6"
								data-aos="fade-up">
								<router-link :to="{name:'ProductsDetail', params:{slug:v2.mp_slug}}" class="prod-item">
									<img :src="uploader(v2.mp_image)" :alt="v2.mp_title" :title="v2.mp_title">
									<h4 v-html="v2.mp_title"></h4>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="modal fade" id="nutriFact" tabindex="-1" role="dialog" aria-modal="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Nutrition Facts - {{detail.mp_title}}</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<img :src="uploader(detail.mp_nutrition_fact)" alt="Sweetener Classic Nutrition Facts">
					</div>
				</div>
			</div>
		</div>
	</section>
</template>